import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../App';
import { getInviteDetails, getUser, postAcceptInvite, postCreateCompany } from '../../common/api/apiClient';
import { FilledButton } from '../../shared/styled-components/styled_buttons';
import { Body, Headline, Label } from '../../shared/styled-components/styled_text';
import { Invite } from '../../shared/types/responseTypes';
import { appRoutes } from '../../AppRoutes';
import { Header } from '../login/components/Header';
import { doRedirect } from '../../common/utils/utils';
import { getSavedAuth } from '../../common/utils/storage';

export const InviteLanding: React.FC<{ asAccept?: boolean }> = ({ asAccept }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [localInvite, setLocalInvite] = useState<Invite>();
  const { token } = useParams();
  const { invite, setInvite, redirect } = useContext(AppContext);
  const navigate = useNavigate();

  // Set local invite from context when returning to this page to accept the invite (asAccept mode)
  useEffect(() => {
    setLocalInvite(invite);
  }, [invite]);

  useEffect(() => {
    const auth = getSavedAuth();
    if (auth) {
      getUser(auth?.access_token).then(user => {
        if (user.companies.length) {
          doRedirect(redirect, auth);
        } else {
          setIsLoggedIn(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    console.log('invite', invite);
    console.log('localInvite', localInvite);
  }, [invite, localInvite]);

  useEffect(() => {
    if (token) {
      getInviteDetails(token)
        .then(setLocalInvite)
        .catch(() => {
          alert('유효하지 않은 초대장입니다.');
          navigate(appRoutes.login.path());
        });
    }
  }, [token]);

  const handleAccept = async () => {
    if (!asAccept && !isLoggedIn) {
      if (localInvite) {
        setInvite(localInvite);
      }
      return navigate(appRoutes.login.path());
    }

    /*
     * If user is already logged in there's only a local invite
     * Page is essentially acting in `asAccept` mode
     */
    if (!invite && !localInvite) throw new Error('No invite!');

    const auth = getSavedAuth();
    if (!auth) throw new Error('No auth!');

    try {
      await postAcceptInvite(invite?.token ?? localInvite!.token);
      doRedirect(redirect, auth);
    } catch (e) {
      console.error(e);
    }
  };

  const handleRefuseInvite = () => {
    if (!asAccept && !isLoggedIn) return navigate(appRoutes.login.path());

    postCreateCompany(redirect);
  };

  return (
    <Container>
      <Header width={'calc(100% - 18rem)'} light />
      <Welcome src={'/images/login/welcome_text.png'} />
      <Background />
      {localInvite && (
        <Wrapper>
          <div>
            <Headline size={'m'}>
              {localInvite.executor.name}님이 보내신
              <br />
              초대장을 확인했어요.
            </Headline>
            <Body size={'m'} style={{ color: 'var(--content-color-tertiary)', marginTop: '0.2rem' }}>
              {localInvite.executor.name}님이 {localInvite.workspace.name}에 초대했어요.
            </Body>
          </div>

          <FilledButton size={'m'} color={'primary'} style={{ width: '100%' }} onClick={handleAccept}>
            {asAccept || isLoggedIn ? '초대 수락하기' : '로그인'}
          </FilledButton>
          <Footer>
            <Body size={'s'} style={{ color: 'var(--content-color-tertiary)' }}>
              모르는 회사인가요?
            </Body>
            <TextLink size={'s'} onClick={handleRefuseInvite}>
              새로운 캔디데이트 만들기
            </TextLink>
          </Footer>
        </Wrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  --_content-width: 35.5rem;
  width: 100vw;
  width: 100svw;
  max-width: 100vw;
  max-width: 100svw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-clip: content-box;

  &:before {
    content: 'Welcome';
    font-size: 20px;
    -webkit-text-fill-color: transparent;
  }
`;

const Background = styled.div`
  background-color: steelblue;
  background-image: url('/images/login/invite_background.png');
  background-size: cover;
  background-position: center;
  position: absolute;
  inset: 2vw;
  z-index: -1;
  border-radius: 16px;
  transform: scaleX(-1);

  @media screen and (max-width: 430px) {
    inset: 0;
    border-radius: 0;
  }
`;

const Welcome = styled.img`
  width: var(--_content-width);
`;

const Wrapper = styled.div`
  min-width: var(--_content-width);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2.4rem;
  padding: var(--padding-modal);
  background-color: white;
  border-radius: 16px;
  border: 1px solid var(--border-color);
  box-sizing: border-box;

  &:before {
    content: '';
    width: 4rem;
    height: 4rem;
    background-image: url('/images/common/local_post_office.svg');
    background-size: 100%;
    background-position: center;
  }

  > div:first-of-type {
    margin-top: -2.2rem;
  }
`;

const Footer = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  border-top: 1px solid var(--border-color);
`;

const TextLink = styled(Label)`
  cursor: pointer;
  border-bottom: 1px solid transparent;

  &:hover {
    border-color: black;
  }
`;
