import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { FlexCol } from '../../../../shared/FlexCol';
import { Title } from '../../../../shared/styled-components/styled_text';
import { FilledButton } from '../../../../shared/styled-components/styled_buttons';
import { AppContext } from '../../../../App';

export const UserInfoAccount: React.FC = () => {
  const { leaveService, userInfo } = useContext(AppContext);
  return (
    <Container>
      <>
        <FlexCol align={'flex-start'} width={'100%'} gap={'1.2rem'}>
          <div>
            <Title size={'s'}>
              캔디데이트에서 <br />
              계정을 완전히 삭제해요.
            </Title>
          </div>

          <FilledButton
            size={'s'}
            color={'mono'}
            icon={{ slot: 'end', src: '/images/common/exit.svg' }}
            style={{ width: '100%' }}
            onClick={() => {
              userInfo.close();
              leaveService.open();
            }}
          >
            탈퇴하기
          </FilledButton>
        </FlexCol>
      </>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  padding-top: 2.4rem;
`;
