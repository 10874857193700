import KakaoLogin from 'react-kakao-login';
import { postSignInBySocial } from '../../../common/api/apiClient';
import React, { useContext } from 'react';
import { Social, SocialLogo } from '../Login';
import { AppContext } from '../../../App';
import { getFallbackRedirect } from '../../../common/utils/utils';
import { useTrack } from '@hackler/react-sdk';
import { setLastLoginType } from '../../../common/utils/storage';

export const Kakao: React.FC<{ onSocial: (social: Social) => void; onToken: () => void }> = ({ onSocial, onToken }) => {
  const { redirect } = useContext(AppContext);
  const track = useTrack();

  const handleSuccess = async ({ response, profile }: { response: LoginResponse; profile?: UserProfile }) => {
    const res = await postSignInBySocial(redirect ?? getFallbackRedirect(), 'kakao', response.access_token);
    if (res) {
      track({ key: 'click_login_kakao' });
      onSocial({ provider: 'kakao', access_token: response.access_token, ...res });
    } else {
      setLastLoginType('kakao');
      track({ key: 'click_signup_kakao' });
      onToken();
    }
  };

  return (
    <KakaoLogin
      token={'2f792e91b4c8873c68185f5228568ad5'}
      onSuccess={handleSuccess}
      onFail={console.error}
      render={({ onClick }) => <SocialLogo label={'카카오톡'} src={'/images/login/kakao.svg'} onClick={onClick} id={'kakao-login'} />}
    />
  );
};

interface LoginResponse {
  token_type: string;
  access_token: string;
  expires_in: string;
  refresh_token: string;
  refresh_token_expires_in: number;
  scope: string; // 범위가 여러 개일 경우, 공백으로 구분
}

interface UserProfile {
  id: number;
  kakao_account: KakaoAccount;
  synched_at: string;
  connected_at: string;
  properties: Profile;
}

interface KakaoAccount {
  /** 프로필 정보 */
  profile: Profile;
  /** 대표 이메일 */
  email: string;
  /** 연령대 ref: https://developers.kakao.com/docs/latest/ko/kakaologin/common#user-info */
  age_range: string;
  /** 생일, MMDD 형식 */
  birthday: string;
  /** 태어난 해, YYYY 형식 */
  birthyear: string;
  /** 성별, female/male */
  gender: 'female' | 'male';
  /** 전화번호. 카카오톡에 등록된 전화번호 */
  phone_number: string;
  /** 연계정보. 암호화된 이용자 확인 값 */
  ci: string;
}

interface Profile {
  /** 닉네임 */
  nickname: string;
  /** 프로필 이미지 URL, 640px * 640px 또는 480px * 480px */
  profile_image: string;
  /** 프로필 미리보기 이미지 URL, 110px * 110px 또는 100px * 100px */
  thumbnail_image_url: string;
  /** 사용자 동의 시 프로필 제공 가능 */
  profile_needs_agreement?: boolean;
}
