import { setSavedAuth } from './storage';
import { AuthResponse } from '../../shared/types/responseTypes';

export const isDevEnv = () => process.env.REACT_APP_ENV === 'dev';

export const getFallbackRedirect = () => {
  if (window.location.href.includes('localhost')) return 'http://localhost:3000';
  if (isDevEnv()) return 'https://test.candidate.im';
  return 'https://service.candidate.im';
};

// export const getFallbackOrigin = () => {
//   if (window.location.href.includes('localhost') || window.location.href.includes('192.168') || isDevEnv()) {
//     return 'test';
//   }
//   return 'service';
// };

export const doRedirect = (redirect: string, auth: AuthResponse) => {
  let href = '';
  href = redirect;
  if (href?.includes('?')) href += `&auth=${JSON.stringify({ ...auth, isLogin: true })}`;
  else href += `?auth=${JSON.stringify(auth)}`;

  setSavedAuth({ ...auth, isLogin: true });

  href = encodeURI(href);
  window.location.href = href;
};

export const doSyncRedirect = (redirect: string, accessToken: string) => {
  const href = decodeURIComponent(redirect);
  window.location.href = `${href}&access_token=${accessToken}`;
};

export function formatPhoneNumber(number: string) {
  // Remove any non-number characters
  const cleanString = number.replace(/[^0-9]+/g, '');
  let formatted = '';
  for (let i = 0; i < cleanString.length; i++) {
    if (i === 3 || i == 7) {
      formatted += '-';
    }
    formatted += cleanString[i];
  }
  formatted.replace(/--+/g, '-');
  if (formatted.length > 13) {
    formatted = formatted.slice(0, 13);
  }

  return formatted;
}
