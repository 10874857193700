import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import AppRoutes from './AppRoutes';
import { DialogUserInfo } from './common/components/DialogUserInfo/DialogUserInfo';
import { Invite } from './shared/types/responseTypes';
import { DialogChangePassword } from './common/components/DialogChangePassword';
import { DialogLeaveService } from './common/components/DialogLeaveService';
import { isDevEnv } from './common/utils/utils';
import { setSavedAuth } from './common/utils/storage';
import { DialogEditUserInfo } from './common/components/DialogEditUserInfo';
import { useLocation } from 'react-router-dom';

interface ModalControl {
  open: () => void;
  close: () => void;
}

interface Context {
  redirect: string;
  userInfo: ModalControl;
  changePassword: ModalControl;
  leaveService: ModalControl;
  editUserInfo: {
    open: (type: 'name' | 'email' | 'phone', callback?: () => void) => void;
    close: () => void;
  };
  action: string | undefined;
  invite?: Invite;
  setInvite: (invite: Invite) => void;
}

export const AppContext = React.createContext({} as Context);

function App() {
  const [userDialog, setUserDialog] = useState(false);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [leaveDialog, setLeaveDialog] = useState(false);
  const [editUserInfoDialog, setEditUserInfoDialog] = useState<'name' | 'email' | 'phone' | null>(null);
  const editUserInfoCallback = useRef<() => void>();

  const [redirect, setRedirect] = useState<string>('');
  const [action, setAction] = useState<string>();
  const [invite, setInvite] = useState<Invite>();
  const location = useLocation();

  console.log(window.location.pathname);

  useEffect(() => {
    // If true, we're in a pop-up window.
    // Post message to original tab and handle in Naver.jsx
    if (window.location.pathname.includes('social-login/naver/callback') && location.hash) {
      const accessToken = location.hash.substring(location.hash.indexOf('=') + 1, location.hash.indexOf('&state'));

      window.opener.postMessage({
        callback: 'naver',
        accessToken,
      });

      window.close();
    }
  }, [location]);

  // Get url params
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let r = params.get('redirect');
    const token = params.get('authToken');
    const a = params.get('action');

    if (token) {
      setSavedAuth(JSON.parse(decodeURIComponent(token)));
    }

    if (a) {
      setAction(a);
    }

    if (!r) {
      r = isDevEnv() ? 'https://test.candidate.im' : 'https://service.candidate.im';
    }

    const appleLoginState = localStorage.getItem('apple-login-state');
    if (appleLoginState) {
      const parsed = JSON.parse(appleLoginState);
      r = parsed.redirect;
      localStorage.removeItem('apple-login-state');
    }

    setRedirect(r ?? 'https://service.candidate.im');
  }, []);

  /*
						Action handler. Actions come from mobile app to handle lack of naver/apple plugins
						Wait for origin to be set + 250ms for page to render.
						Click socials.
						local storage is cleared to ensure login by selected provided occurs
					 */

  useEffect(() => {
    if (redirect && action) {
      localStorage.clear();
      setTimeout(() => {
        if (action === 'naver') {
          const naver = document.getElementById('naverIdLogin_loginButton');
          if (naver) naver.click();
        }

        if (action === 'apple') {
          const apple = document.getElementById('apple-login');
          if (apple) apple.click();
        }
      }, 2500);
    }
  }, [redirect, action]);

  const handleOpenEditUserInfo = (type: 'name' | 'email' | 'phone', callback?: () => void) => {
    setEditUserInfoDialog(type);
    editUserInfoCallback.current = callback;
  };

  const handleCloseEditUserInfo = () => {
    setEditUserInfoDialog(null);
    if (editUserInfoCallback.current) {
      editUserInfoCallback.current();
      editUserInfoCallback.current = undefined;
    }
  };

  return (
    <AppContext.Provider
      value={{
        redirect,
        userInfo: { open: () => setUserDialog(true), close: () => setUserDialog(false) },
        changePassword: { open: () => setPasswordDialog(true), close: () => setPasswordDialog(false) },
        leaveService: { open: () => setLeaveDialog(true), close: () => setLeaveDialog(false) },
        editUserInfo: { open: handleOpenEditUserInfo, close: handleCloseEditUserInfo },
        action,
        invite,
        setInvite,
      }}
    >
      <AppRoutes />
      <DialogUserInfo open={userDialog} />
      <DialogChangePassword open={passwordDialog} />
      <DialogLeaveService open={leaveDialog} />
      <DialogEditUserInfo type={editUserInfoDialog} />
    </AppContext.Provider>
  );
}

export default App;
