import React, { forwardRef, useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import { RegEx } from '../../common/utils/RegEx';

interface Icon {
	src: string;
	onClick?: () => void;
	width?: string;
	needsInput?: boolean;
}

interface Props {
	value: string;
	onChange: (value: string) => void;
	onFile?: (file: FileList | null) => void;
	label?: string | React.ReactNode;
	pattern?: RegExp | ((value: string) => boolean | undefined);
	patternMessage?: string;
	type?: string;
	hint?: string;
	errorMessage?: string;
	icons?: {
		start?: Icon;
		end1?: Icon;
		end2?: Icon;
	};
	autocomplete?: boolean;
	placeholder?: string;
	style?: React.CSSProperties;
	containerStyle?: React.CSSProperties;
	disabled?: boolean;
	required?: boolean;
	min?: number | string;
	max?: number | string;
	initialValue?: string;
	id?: string;
	onBlur?: () => void;
	onFocus?: () => void;
	asFile?: boolean;
	onEnterKey?: () => void;
	highlightRequired?: boolean;
}

export const IconInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
	const {
		label,
		value,
		onChange,
		onFile,
		pattern,
		hint,
		errorMessage,
		icons,
		autocomplete,
		placeholder,
		style,
		containerStyle,
		disabled,
		patternMessage,
		type,
		required,
		min,
		max,
		initialValue,
		id,
		onBlur,
		onFocus,
		asFile,
		onEnterKey,
		highlightRequired,
	} = props;
	// returns false for invalid, true for valid, and undefined for not checked (default state)
	const isValid = useMemo(() => {
		if (!pattern && !!value) return true;
		if (!pattern && !value) return undefined;
		if (!value) return undefined;
		if (value === initialValue) return false;

		if (typeof pattern === 'function') {
			return pattern(value);
		} else {
			return pattern!.test(value);
		}
	}, [pattern, value]);

	const handleChange = (val: string) => {
		const cleaned = val.replaceAll(' ', '').replaceAll('-', '');
		if (type === 'tel') {
			if (typeof max === 'number' && max && cleaned.length > max) return;
			if (cleaned.length && !RegEx.Numeric.test(cleaned)) return;
			return onChange(cleaned);
		}

		if (typeof max === 'number' && val.length > max) return;
		onChange(val);
	};

	return (
		<Container style={containerStyle}>
			{label && <Label>{label}</Label>}
			<Wrapper
				style={style}
				isValid={isValid}
				hasError={!!errorMessage?.trim()}
				disabled={disabled}
				asFile={asFile}
				highlightRequired={!!highlightRequired}
			>
				{icons?.start && (icons.start.needsInput ? !!value : true) && (
					<Icon src={icons.start.src} onClick={icons.start.onClick} asStart={true} width={icons.start.width} />
				)}
				<Input
					ref={ref}
					id={id}
					value={value ? value : initialValue ? initialValue : ''}
					onChange={e => (type === 'file' ? onFile!(e.target.files) : handleChange(e.target.value))}
					autoComplete={autocomplete ? 'on' : 'one-time-code'}
					placeholder={placeholder}
					disabled={disabled || asFile}
					required={required}
					type={type ?? 'text'}
					min={min}
					max={max}
					onBlur={onBlur}
					onFocus={onFocus}
					onKeyDown={e => {
						if (onEnterKey && e.key === 'Enter') onEnterKey();
					}}
				/>
				{icons?.end1 && (icons.end1.needsInput ? !!value : true) && (
					<Icon src={icons.end1.src} onClick={icons.end1.onClick} style={{ marginRight: '12px' }} width={icons.end1.width} />
				)}
				{icons?.end2 && (icons.end2.needsInput ? !!value : true) && (
					<Icon src={icons.end2.src} onClick={icons.end2.onClick} width={icons.end2.width} />
				)}
			</Wrapper>
			{(errorMessage || (isValid === false && patternMessage) || hint) && (
				<HelperText hasError={!!errorMessage || isValid === false}>
					{errorMessage ?? (isValid === false ? patternMessage : undefined) ?? hint}
				</HelperText>
			)}
		</Container>
	);
});

IconInput.displayName = 'IconName';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
  overflow: hidden;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: var(--mono-40);
`;

const inputBorderColor = (hasError: boolean, isValid?: boolean, highlightRequired?: boolean) => {
	if (hasError || isValid === false) return '--error';
	if (isValid !== true && highlightRequired === true) return '--error';
	if (isValid === true) return '--primary';
	return '--light';
};

const Wrapper = styled.div<{
	isValid?: boolean;
	hasError: boolean;
	disabled?: boolean;
	asFile?: boolean;
	highlightRequired: boolean;
}>`
  width: 100%;
  min-height: 3.8rem;
  height: 3.8rem;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: 0 1.2rem;
  background-color: #fdfdfd;
  border: 1px solid var(${props => inputBorderColor(props.hasError, props.isValid, props.highlightRequired)});
  border-radius: 4px;
  box-sizing: border-box;

  &:focus-within {
    border-color: ${props => (props.isValid === false ? 'var(--error)' : 'var(--primary)')};
  }

  ${({ disabled, asFile }) =>
          disabled &&
          !asFile &&
          css`
            border-color: var(--light);
            color: var(--mono-60);
            background-color: var(--light-1);
          `}
`;

const Icon = styled.img<{ asStart?: boolean; width?: string }>`
  height: 2rem;
  width: ${props => props.width ?? '2rem'};
  object-fit: cover;
  filter: var(--placeholder-filter);
  flex-shrink: 0;

  ${({ asStart }) =>
          !asStart &&
          css`
            cursor: pointer;
          `}
`;

const Input = styled.input<{ asFile?: boolean }>`
  flex-grow: 1;
  flex-shrink: 1;
  border: none;
  outline: none;
  height: 2.2rem;
  font-weight: 400;
  font-size: 1.4rem;
  max-width: 100%;
  min-width: 0;

  &::placeholder {
    color: var(--placeholder);
    font-weight: 400;
    font-size: 1.4rem;
    padding: 0;
  }

  ${({ asFile }) =>
          !asFile &&
          css`
            &:disabled {
              color: var(--light);
              background-color: var(--light-1);
              overflow: hidden;
              text-overflow: ellipsis;
            }
          `}
`;

const HelperText = styled.div<{ hasError: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: var(--${props => (props.hasError ? 'error' : 'placeholder')});
  white-space: pre-wrap;
  padding-left: 1rem;
`;
