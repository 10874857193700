import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import OptionSelect, { OptionButton } from '../../shared/components/OptionSelect';
import { Body, Title } from '../../shared/styled-components/styled_text';
import { useUser } from '../../common/utils/hooks';
import { Header } from '../login/components/Header';
import { AppContext } from '../../App';
import { postCreateCompany } from '../../common/api/apiClient';
import { getSavedAuth } from '../../common/utils/storage';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../AppRoutes';

export const CreateWorkspace: React.FC = () => {
  const { user } = useUser();
  const { redirect } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getSavedAuth();
    if (!auth) navigate(appRoutes.login.path());
  }, []);

  const handleClick = () => {
    postCreateCompany(redirect);
  };

  const button: OptionButton = {
    icon: '/images/common/corporate_fare.svg',
    content: (
      <>
        <Title size={'s'}>새 워크스페이스</Title>
        <Body size={'s'} style={{ color: 'var(--content-color-tertiary)' }}>
          워크스페이스를 생성하고 함께
          <br />
          사용할 멤버를 초대해보세요.
        </Body>
      </>
    ),
    buttonLabel: '생성하기',
    onClick: handleClick,
    key: 'create-workspace',
  };

  return (
    <Container>
      <Header withAccount withLogout />
      <OptionSelect title={`${user?.name}님, 안녕하세요. 반가워요!`} buttons={[button]} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
