import axios from 'axios';
import { getApiUrl } from './apiClient';

export type UploadType = 'profile' | 'interviewee' | 'logo' | 'feedback';

export interface PreSignedUrlRequest {
  type: UploadType;
  id?: string;
  name_org: string;
}

export interface UUIDResponse {
  bucket: string;
  path: string;
  uuid: string;
  name_org: string;
  name: string;
  pre_signed_url?: string;
}

export interface UploadSuccess {
  bucket: string;
  uuid: string;
  path: string;
  name: string;
  name_org: string;
}

export const postFetchUploadURLs = async (token: string, files: PreSignedUrlRequest[]): Promise<UUIDResponse[]> => {
  try {
    const { data } = await axios.post(`${getApiUrl()}files/pre-signed-urls`, { files }, { headers: { Authorization: `Bearer ${token}` } });
    return data.data.pre_signed_urls as UUIDResponse[];
  } catch (err) {
    console.error(err);
  }
  return [];
};

export const postUploadSuccess = async (token: string, files: UploadSuccess[]) => {
  await axios.post(`${getApiUrl()}files`, { files }, { headers: { Authorization: `Bearer ${token}` } });
};

export const uploadFile = async (file: File, url: string) => {
  const { data } = await axios.put(url, file, {
    headers: {
      Accept: '*/*',
      'Content-Type': 'image/png',
      'Content-Disposition': 'attachment',
    },
  });
};

export const postGetErrorPresignedUrl = async (token: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(`${getApiUrl()}files/error`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }).then(res => {
      if (res.ok && res.status === 200) {
        res.json().then(data => {
          resolve(data.pre_signed_url);
        });
      } else {
        reject();
      }
    });
  });
};

export const uploadError = async (token: string, blob: Blob) => {
  const url = await postGetErrorPresignedUrl(token);
  await axios.put(url, blob, {
    headers: {
      Accept: '*/*',
      'Content-type': 'application/json',
      'Content-Disposition': 'attachment',
    },
  });
};
