import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { createInstance, HackleProvider } from '@hackler/react-sdk';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const keyHackleBrowserDev = 'K8rjfyaMDMaKOPuMWuGDq7bLwZGZvxBF';
const keyHackleBrowserProd = 'V9y2D4VUYS4LGRGb9SJWYeuwXyRxW11c';

const client = createInstance(process.env.NODE_ENV === 'development' ? keyHackleBrowserDev : keyHackleBrowserProd, {
  debug: false,
  auto_track_page_view: true,
});

root.render(
  <HackleProvider hackleClient={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HackleProvider>,
);
