import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useOnClickOutside, useOpenCloseDialog } from '../utils/hooks';
import { AppContext } from '../../App';
import { getSavedAuth, LAST_LOGIN_TYPE, setSavedAuth } from '../utils/storage';
import { deleteLeaveService } from '../api/apiClient';
import { FlexRow } from '../../shared/FlexRow';
import { FilledButton, OutlineButton } from '../../shared/styled-components/styled_buttons';

interface Props {
  open: boolean;
}

export const DialogLeaveService: React.FC<Props> = ({ open }) => {
  const [accepted, setAccepted] = useState(false);

  const { leaveService } = useContext(AppContext);
  const self = useRef<HTMLDialogElement>(null);
  useOpenCloseDialog(self, open);

  const container = useRef<HTMLDivElement>(null);
  useOnClickOutside(container, handleClose);

  const handleLeave = async () => {
    const auth = getSavedAuth();
    await deleteLeaveService(auth!.access_token);

    localStorage.removeItem(LAST_LOGIN_TYPE);
    setSavedAuth(null);
    window.location.reload();
  };

  function handleClose() {
    leaveService.close();
  }

  return (
    <dialog ref={self} onClose={handleClose} onCancel={handleClose}>
      <Container>
        <Company src={`/images/common/exit.svg`} />
        <Title>
          캔디데이트를 <br /> 탈퇴하기 전에 확인해주세요
        </Title>

        <Section>
          <FlexRow justify={'flex-start'} gap={'0.4rem'}>
            <Icon src={'/images/common/trash_can.svg'} />
            <div>모든 개인정보는 모두 삭제돼요</div>
          </FlexRow>
          <FlexRow justify={'flex-start'} gap={'8.2rem'}>
            <div style={{ color: 'var(--mono-40)', fontSize: '1.6rem', fontWeight: 400 }}>프로필정보</div>
            <div style={{ color: 'var(--mono-60)', fontSize: '1.6rem', fontWeight: 400 }}>이메일 주소, 이름</div>
          </FlexRow>
        </Section>

        <Section>
          <FlexRow justify={'flex-start'} gap={'0.4rem'}>
            <Icon src={'/images/common/folder.svg'} />
            <div>일부 정보는 남아있어요</div>
          </FlexRow>
          <FlexRow justify={'flex-start'} gap={'8.2rem'}>
            <div style={{ color: 'var(--mono-40)', fontSize: '1.6rem', fontWeight: 400 }}>활동내역</div>
          </FlexRow>
        </Section>

        <Section>
          <Confirm accepted={accepted} onClick={() => setAccepted(prev => !prev)}>
            <input checked={accepted} type={'checkbox'} onChange={() => undefined} />
          </Confirm>
        </Section>
      </Container>
      <Footer>
        <OutlineButton size={'m'} color={'mono'} onClick={handleClose}>
          취소
        </OutlineButton>
        <FilledButton size={'m'} color={'error'} disabled={!accepted} onClick={handleLeave}>
          탈퇴하기
        </FilledButton>
      </Footer>
    </dialog>
  );
};

const Container = styled.div`
  width: 42.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 4rem 4rem;
`;

const Company = styled.img`
  width: 3.2rem;
  height: 3.2rem;
  object-fit: cover;
`;

const Title = styled.div`
  font-size: 2.4rem;
  font-weight: 700;
  color: var(--mono-10);
  line-height: 3.2rem;
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 2rem 0 1.8rem;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--mono-10);

  & + & {
    border-top: 1px solid #f0f2f3;
  }
`;

const Icon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  object-fit: cover;
`;

const Confirm = styled.div<{ accepted: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;
  color: var(${props => (props.accepted ? '--mono-10' : '--mono-60')});

  > input {
    &:before {
      display: none;
      content: '';
      width: 1.3rem;
      height: 1.3rem;
      border: 1px solid var(--primary);
      border-radius: 2px;
      cursor: pointer;
    }
  }

  &:after {
    content: '위의 내용을 확인했어요. 동의합니다.';
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  &:hover {
    color: var(--primary);

    > input:not(:checked) {
      &:before {
        display: block;
      }
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.8rem 3rem;
  gap: 1.2rem;
  box-sizing: border-box;
`;
