import styled, { css } from 'styled-components/macro';
import './styled_vars.css';

export type BtnSizes = 'xs' | 's' | 'm' | 'l';
export type BtnColors = 'primary' | 'error' | 'mono' | 'green';

export interface BtnIcon {
	slot: 'start' | 'end';
	src: string;
	rotate?: string;
}

const Button = styled.button<{
	size: BtnSizes;
	icon?: BtnIcon;
	color: BtnColors;
	iconOnlyBp?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${props => (props.icon?.slot === 'end' ? 'row-reverse' : 'row')};
  gap: 4px;
  outline: none;
  margin: 0;
  border: none;
  box-sizing: border-box;
  font-weight: 500;
  border-radius: 4px;
  min-width: fit-content;
  width: fit-content;
  cursor: pointer;
  height: var(--btn-height-${props => props.size});
  min-height: var(--btn-height-${props => props.size});
  font-size: var(--btn-font-${props => props.size});
  padding: var(--btn-padding-${props => props.size});
  user-select: none;
  flex-shrink: 0;
  position: relative;

  ${({ size, icon, color }) =>
          icon &&
          css`
            padding: var(--btn-padding-icon-${size});

            &:before {
              content: '';
              width: var(--btn-icon-size-${size});
              height: var(--btn-icon-size-${size});
              background-image: url(${icon.src});
              background-size: cover;
              filter: var(--btn-icon-filter-${color});
              transform: rotate(${icon.rotate ?? '0deg'});
            }
          `}
  ${({ iconOnlyBp, size }) =>
          iconOnlyBp &&
          css`
            @media screen and (max-width: ${iconOnlyBp}px) {
              font-size: 0px;
              min-width: min-content !important;
              padding: var(--btn-padding-${size});
            }
          `}
  &:disabled {
    cursor: not-allowed;
  }
`;

export const FilledButton = styled(Button)`
  background-color: var(--btn-bg-${props => props.color});
  color: var(--btn-font-color-${props => props.color});
  min-width: 8rem;

  &:hover {
    background-color: var(--btn-hover-${props => props.color});
  }

  &:active {
    background-color: var(--btn-bg-active-${props => props.color});
    color: var(--btn-font-color-active-${props => props.color});

    &:before,
    &:after {
      filter: var(--btn-icon-filter-active-${props => props.color});
    }
  }

  &:disabled {
    background-color: var(--light-1);
    color: var(--mono-80);

    &:before,
    &:after {
      filter: var(--btn-icon-filter-disabled);
    }
  }
`;

export const OutlineButton = styled(Button)`
  background-color: white;
  border: 1px solid var(--btn-outline-border-${props => props.color});
  color: var(--btn-outline-font-color-${props => props.color});

  &:before,
  &:after {
    filter: var(--btn-outline-icon-filter-${props => props.color});
  }

  &:hover {
    background-color: var(--btn-outline-bg-hover-${props => props.color});
  }

  &:active {
    color: var(--btn-outline-border-active-${props => props.color});
    border-color: var(--btn-outline-border-active-${props => props.color});
    background-color: var(--btn-outline-bg-active-${props => props.color});

    &:before,
    &:after {
      filter: var(--btn-outline-icon-filter-active-${props => props.color});
    }
  }

  &:disabled {
    color: var(--mono-80);
    border-color: var(--mono-80);
    background-color: white;

    &:before,
    &:after {
      filter: invert(79%) sepia(7%) saturate(143%) hue-rotate(178deg) brightness(106%) contrast(86%);
    }
  }
`;

export const TextButton = styled(OutlineButton)`
  border: none;
  min-width: fit-content;
  background-color: transparent;
`;
