import { useGoogleLogin } from '@react-oauth/google';
import { postSignInBySocial } from '../../../common/api/apiClient';
import { Social, SocialLogo } from '../Login';
import React, { useContext } from 'react';
import { AppContext } from '../../../App';
import { getFallbackRedirect } from '../../../common/utils/utils';
import { useTrack } from '@hackler/react-sdk';
import { setLastLoginType } from '../../../common/utils/storage';

export const Google: React.FC<{
  onSocial: (social: Social) => void;
  onToken: () => void;
}> = ({ onSocial, onToken }) => {
  const { redirect } = useContext(AppContext);
  const track = useTrack();

  const loginPopUp = useGoogleLogin({
    onSuccess: codeResponse => onSuccess(codeResponse.access_token),
  });
  // const loginRedirect = useGoogleLogin({
  //   onSuccess: codeResponse => console.log(codeResponse),
  //   flow: 'auth-code',
  //   ux_mode: 'redirect',
  //   redirect_uri: 'http://localhost:3003/social-login/google/callback',
  // });

  // const handleLogin = () => {
  //   if (action === 'google') {
  //     loginRedirect();
  //   } else {
  //     loginPopUp();
  //   }
  // };

  const onSuccess = async (access_token: string) => {
    const res = await postSignInBySocial(redirect ?? getFallbackRedirect(), 'google', access_token);
    if (res) {
      track({ key: 'click_login_google' });
      onSocial({ provider: 'google', access_token: access_token, ...res });
    } else {
      setLastLoginType('google');
      track({ key: 'click_signup_google' });
      onToken();
    }
  };

  const onError = () => {
    console.error('Google login error');
  };

  return <SocialLogo label={'구글'} src={'/images/login/google.svg'} onClick={() => loginPopUp()} id={'google-login'} />;
};
