import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { Label } from '../../../shared/styled-components/styled_text';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../AppRoutes';
import { setSavedAuth } from '../../../common/utils/storage';
import { AppContext } from '../../../App';

interface Props {
  withLogout?: boolean;
  withAccount?: boolean;
  light?: boolean;
  width?: string;
}

export const Header: React.FC<Props> = ({ withLogout, withAccount, light, width }) => {
  const navigate = useNavigate();
  const { userInfo } = useContext(AppContext);

  return (
    <Container width={width}>
      <img
        src={`/images/common/logo_name_${light ? 'light' : 'dark'}.svg`}
        alt={'home'}
        onClick={() => (window.location.href = 'https://candidate.im')}
      />
      <AccountMenuContainer>
        {withAccount && (
          <Label size={'s'} onClick={userInfo.open} style={{ marginLeft: '3rem' }}>
            계정정보
          </Label>
        )}
        {withLogout && (
          <Label
            size={'s'}
            onClick={() => {
              setSavedAuth(null);
              navigate(appRoutes.login.path());
            }}
            style={{ marginLeft: '3rem' }}
          >
            로그아웃
          </Label>
        )}
      </AccountMenuContainer>
    </Container>
  );
};

const Container = styled.div<{ width?: string }>`
  height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 8.8rem;
  left: 9rem;
  right: 9rem;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  > img {
    height: 24px;

    @media screen and (max-width: 599px) {
      display: none;
    }
  }
`;

const AccountMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
