import React from 'react';
import styled from 'styled-components/macro';
import { FlexRow } from '../../../shared/FlexRow';

export const Footer: React.FC = () => {
  return (
    <Container>
      <FlexRow gap={'1.4rem'}>
        <a target='_blank' rel='noopener noreferrer' href={'https://www.candidate.im/teams/use'}>
          이용 약관
        </a>
        <span>|</span>
        <a target='_blank' rel='noopener noreferrer' href='https://www.candidate.im/teams/privacy'>
          개인정보처리방침
        </a>
      </FlexRow>
      <div>© 2023 candidate. co. ltd. All Rights Reserved</div>
    </Container>
  );
};

const Container = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 160%;
  text-align: center;
  color: var(--placeholder);

  > div > a,
  span {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 160%;
    color: var(--mono-60);
    text-decoration: none;
  }
`;
