import React, { useContext, useEffect, useRef, useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Google } from './components/Google';
import { Naver } from './components/Naver';
import { Kakao } from './components/Kakao';
import { useNavigate } from 'react-router-dom';
import { getUser, postSignIn, postSignUpBySocial } from '../../common/api/apiClient';
import { Container, Divider, Form } from './components/styled';
import { Body, Headline } from '../../shared/styled-components/styled_text';
import { IconInput } from '../../shared/components/IconInput';
import { FilledButton, TextButton } from '../../shared/styled-components/styled_buttons';
import { FlexRow } from '../../shared/FlexRow';
import { Footer } from './components/Footer';
import { FlexCol } from '../../shared/FlexCol';
import { useBoundingClient } from '../../common/utils/hooks';
import { DialogPasswordReset } from './DialogPasswordReset';
import { Header } from './components/Header';
import { AppContext } from '../../App';
import { appRoutes } from '../../AppRoutes';
import { getLastLoginType, getSavedAuth, setLastLoginType, setSavedAuth } from '../../common/utils/storage';
import { doRedirect, getFallbackRedirect, isDevEnv } from '../../common/utils/utils';
import dayjs from 'dayjs';
import { AgreementsCheck, SignUpAgreements } from './components/SignUpAgreements';
import { AuthResponse } from '../../shared/types/responseTypes';
import { Apple } from './components/Apple';
import styled from 'styled-components/macro';
import { useTrack, useVariationDetail } from '@hackler/react-sdk';

export interface Social {
  provider: 'kakao' | 'naver' | 'google' | 'apple';
  name?: string;
  email?: string;
  access_token: string;
}

/*
  If the user does social login but doesn't yet have an account,
  they complete the signup process here.
*/

export const Login: React.FC = () => {
  const [userId, setUserId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [userIdError, setUserIdError] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();
  const [resetPassword, setResetPassword] = useState(false);

  const [social, setSocial] = useState<Social>();
  const [name, setName] = useState('');
  const [agreements, setAgreements] = useState<AgreementsCheck>();
  const [lastLoginClient, setLastLoginClient] = useState<DOMRect>();

  const formRef = useRef<HTMLFormElement>(null);
  const { client: formClient } = useBoundingClient(formRef);

  const navigate = useNavigate();
  const { redirect, invite } = useContext(AppContext);
  const track = useTrack();

  const leadTextVariation = useVariationDetail(8).get('auth-readtext', '지원자에게 더 부드럽고 편안하게 연락하세요.');

  useEffect(() => {
    track({ key: 'view_login' });

    testSavedAuth();
  }, []);

  const testSavedAuth = () => {
    const auth = getSavedAuth();
    if (!auth) return;

    const expired = tokenExpired(auth);

    if (expired || !auth.access_token) {
      return setSavedAuth(null);
    }

    handleLoginSuccess(auth);
  };

  /*
    If user belongs to a company, do a redirect.
    Else save auth as temp auth while continuing the signup process
  */
  const handleLoginSuccess = (auth: AuthResponse) => {
    setSavedAuth(auth);
    getUser(auth.access_token)
      .then(user => {
        if (user.companies.length) {
          setSavedAuth(auth);
          doRedirect(redirect ?? getFallbackRedirect(), auth);
        } else if (invite) {
          navigate(appRoutes.inviteAccept.path());
        } else {
          navigate(appRoutes.createWorkspace.path());
        }
      })
      .catch(() => {
        console.error('AUTH ERROR');
        setSavedAuth(null);
      });
  };

  useEffect(() => {
    if (social?.email) {
      setUserId(social.email);
    }
    if (social?.name) {
      setName(social.name);
    }
  }, [social]);

  useEffect(() => {
    const lastLogin = getLastLoginType();
    if (lastLogin) {
      let button: HTMLElement | null = null;
      switch (lastLogin) {
        case 'google': {
          button = document.getElementById('google-login');
          break;
        }
        case 'kakao': {
          button = document.getElementById('kakao-login');
          break;
        }
        case 'naver': {
          button = document.getElementById('naverIdLogin');
        }
      }

      if (button) {
        setLastLoginClient(button.getBoundingClientRect());
      }
    }
  }, []);

  useEffect(() => {
    console.log(lastLoginClient);
  }, [lastLoginClient]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLastLoginType('email');
    handleLogin();
  };

  const handleLogin = () => {
    postSignIn(userId!, password!)
      .then(handleLoginSuccess)
      .catch((code?: number) => {
        if (code && code == 404) {
          setUserIdError('등록되지 않은 아이디입니다');
          setPasswordError(undefined);
        } else {
          setPasswordError('아이디와 비밀번호가 일치하지 않습니다');
          setUserIdError(undefined);
        }
      });
  };

  const handleSocialSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    const auth = await postSignUpBySocial(
      redirect ?? getFallbackRedirect(),
      social!.provider,
      social!.access_token,
      name ?? social!.name!,
      agreements!.terms,
      agreements!.privacy,
      !!agreements?.service,
    );
    console.log(auth);
    handleLoginSuccess(auth);
  };

  const onToken = () => {
    const auth = getSavedAuth();
    if (auth) {
      handleLoginSuccess(auth);
    }
  };

  return (
    <GoogleOAuthProvider clientId={'516661429564-bent9j4fqnrae72qjncpu4v0s77ck4r5.apps.googleusercontent.com'}>
      <Container form={formClient}>
        <Header />
        {!social && (
          <Form ref={formRef} onSubmit={handleSubmit}>
            <>
              <FlexCol align={'flex-start'} width={'100%'} gap={'0.2rem'}>
                <Headline size={'m'}>캔디데이트에서</Headline>
                <Body size={'m'} style={{ color: 'var(--mono-40)' }}>
                  {leadTextVariation}
                </Body>
              </FlexCol>

              <FlexCol align={'flex-start'} width={'100%'} gap={'1.6rem'}>
                <IconInput
                  label={'이메일 또는 휴대폰 번호'}
                  id={'login-inp'}
                  value={userId}
                  onChange={setUserId}
                  placeholder={'아이디를 입력해주세요.'}
                  style={{ width: '100%' }}
                  required={true}
                  errorMessage={userIdError}
                  autocomplete={true}
                />
                <IconInput
                  label={'비밀번호'}
                  value={password}
                  onChange={setPassword}
                  placeholder={'비밀번호를 입력해주세요.'}
                  style={{ width: '100%' }}
                  required={true}
                  errorMessage={passwordError}
                  type={'password'}
                />
              </FlexCol>

              <FlexCol align={'flex-start'} width={'100%'} gap={'0.4rem'} style={{ marginTop: '1rem' }}>
                <FilledButton size={'s'} color={'primary'} style={{ width: '100%' }}>
                  로그인
                </FilledButton>
                <FlexRow gap={'0.4rem'}>
                  <TextButton size={'s'} color={'mono'} onClick={() => setResetPassword(true)} type={'button'}>
                    비밀번호 변경
                  </TextButton>
                  <span style={{ fontSize: '1.4rem', color: 'var(--mono-60)' }}>|</span>
                  <TextButton size={'s'} color={'primary'} onClick={() => navigate(appRoutes.signUp.path())} type={'button'}>
                    회원가입
                  </TextButton>
                </FlexRow>
              </FlexCol>

              <Divider />

              <FlexCol align={'center'} width={'100%'} gap={'1.2rem'}>
                <Body size={'m'}>간편하게 회원가입&로그인하기</Body>
                <FlexRow gap={'1.8rem'}>
                  <Google onSocial={setSocial} onToken={onToken} />
                  <Naver onSocial={setSocial} onToken={onToken} />
                  <Kakao onSocial={setSocial} onToken={onToken} />
                  {isDevEnv() && <Apple onSocial={setSocial} onToken={onToken} />}
                </FlexRow>
              </FlexCol>
            </>
          </Form>
        )}

        {social && (
          <Form ref={formRef} onSubmit={handleSocialSignUp}>
            <IconInput
              label={'아이디'}
              id={'login-inp'}
              value={userId}
              onChange={setUserId}
              placeholder={'이메일 주소 또는 연락처를 입력해주세요.'}
              style={{ width: '100%' }}
              required={true}
              errorMessage={userIdError}
              autocomplete={true}
            />
            <IconInput
              label={'이름'}
              value={name}
              onChange={setName}
              placeholder={'이름을 입력해주세요'}
              style={{ width: '100%' }}
              required={true}
              errorMessage={userIdError}
              autocomplete={true}
            />
            <SignUpAgreements onToggle={setAgreements} style={{ marginTop: '1rem' }} />
            <FilledButton size={'s'} color={'primary'} style={{ width: '100%' }} disabled={!agreements?.privacy && !agreements?.terms}>
              회원가입
            </FilledButton>
          </Form>
        )}
        <Footer />

        {lastLoginClient && (
          <TooltipWrapper client={lastLoginClient}>
            <Tooltip />
          </TooltipWrapper>
        )}
      </Container>
      <DialogPasswordReset open={resetPassword} onClose={() => setResetPassword(false)} />
    </GoogleOAuthProvider>
  );
};

export const tokenExpired = (auth: AuthResponse): boolean => {
  let timeout = 0;
  if (!auth?.expired_at) return true;
  const expires = dayjs(auth.expired_at);
  timeout = expires.diff(dayjs(), 'milliseconds');

  if (isNaN(timeout)) timeout = 0;

  return timeout <= 60000;
};

export const SocialLogo = styled.div<{ src: string; label: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;

  &:before {
    content: '';
    width: 4.6rem;
    height: 4.6rem;
    background-image: url('${props => props.src}');
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }

  &:after {
    content: '${props => props.label}';
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--mono-50);
  }
`;

const TooltipWrapper = styled.div<{ client: DOMRect }>`
  position: absolute;
  left: ${props => props.client.left + props.client.width / 2}px;
  top: ${props => props.client.bottom + 13}px;
  transform: translateX(-50%);
  z-index: 1000;
`;

const Tooltip = styled.div`
  padding: 0.6rem 0.8rem;
  background-color: rgba(27, 29, 31, 0.9);
  font-size: 1.4rem;
  color: white;
  position: relative;

  &:before {
    content: '가장 최근에 로그인 했어요';
    display: block;
  }

  &:after {
    content: '';
    width: 9px;
    height: 9px;
    display: block;
    rotate: 45deg;
    left: 50%;
    top: 0;
    position: absolute;
    background-color: inherit;
    transform: translateX(-50%);
  }
`;
