import React from 'react';
import styled from 'styled-components/macro';
import { Body, Headline } from '../styled-components/styled_text';

export interface OptionButton {
  icon: string;
  content: React.ReactNode;
  buttonLabel: string;
  onClick: () => void;
  key: string;
}

interface Props {
  buttons: OptionButton[];
  title: string;
  subtitle?: string;
}

const OptionSelect: React.FC<Props> = ({ buttons, title, subtitle }) => {
  return (
    <div>
      <Container>
        <Headline size={'s'}>{title}</Headline>
        <OptionContainer>
          {buttons.map(b => (
            <OptionCard key={b.key} buttonLabel={b.buttonLabel} onClick={b.onClick}>
              <img src={b.icon} />
              {b.content}
            </OptionCard>
          ))}
        </OptionContainer>
        {subtitle && (
          <Body size={'s'} style={{ color: 'var(--mono-60)', textAlign: 'center' }}>
            {subtitle}
          </Body>
        )}
      </Container>
    </div>
  );
};
OptionSelect.displayName = 'OptionSelect';
export default React.memo(OptionSelect);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const OptionContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  gap: 1.2rem;
`;

const OptionCard = styled.div<{ buttonLabel: string }>`
  width: 22.6rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--light-2);
  border-radius: 16px;
  padding: 2.4rem 0;
  cursor: pointer;
  background-color: white;

  > img {
    width: 3.2rem;
    height: 3.2rem;
    filter: invert(1) brightness(0);
    margin-bottom: 0.8rem;
    user-select: none;
  }

  &:after {
    content: '${props => props.buttonLabel}';
    width: 16.5rem;
    height: 3.2rem;
    background: #f0f2f3;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #464c52;
    margin-top: 1.2rem;
  }

  &:hover {
    background: var(--primary-op-08);

    &:after {
      background: var(--primary);
      color: white;
    }
  }
`;
