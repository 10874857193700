import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../AppRoutes';

export const LegacyVerification: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const email = params.get('email');

    navigate(appRoutes.signUp.path() + `?token=${token}&email=${email}`);
  }, []);

  return null;
};
