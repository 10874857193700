import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { postRequestPasswordReset } from '../../common/api/apiClient';
import styled from 'styled-components/macro';
import { Body, Headline } from '../../shared/styled-components/styled_text';
import { IconInput } from '../../shared/components/IconInput';
import { FilledButton, TextButton } from '../../shared/styled-components/styled_buttons';
import { useOnClickOutside } from '../../common/utils/hooks';

export const DialogPasswordReset: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const [type, setType] = useState<'email' | 'phone'>('email');
  const [accountId, setAccountId] = useState<string>('');
  const [errored, setErrored] = useState(false);
  const input = useRef<{ reset: () => void }>(null);
  const form = useRef<HTMLFormElement>(null);
  const self = useRef<HTMLDialogElement>(null);

  useOnClickOutside(form, onClose);

  useEffect(() => {
    if (open && self.current && !self.current.open) {
      self.current.showModal();
    } else if (!open && self.current && self.current.open) {
      self.current.close();
    }
  }, [open]);

  useEffect(() => {
    setErrored(false);
  }, [accountId]);

  const handleClose = () => {
    console.log('close');
    setAccountId('');
    setErrored(false);
    input.current?.reset();
    onClose();
  };

  const handleReset = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await postRequestPasswordReset(type, accountId!);
      alert(
        `입력하신 ${
          type === 'email' ? '이메일' : '연락처'
        }로 비밀번호를 변경할 수 있는 링크가 전송되었어요. 1시간 안에 비밀번호 변경 후 로그인해주세요.`,
      );
      onClose();
    } catch {
      setErrored(true);
    }
  };

  return (
    <dialog ref={self} id={'dialog-password-reset'} onClose={handleClose}>
      <Form onSubmit={handleReset} ref={form}>
        <Headline size={'m'}>비밀번호를 모르시나요?</Headline>
        <Body size={'m'}>
          새로운 비밀번호로 변경하실 수 있도록 <br />
          링크를 {type === 'email' ? '이메일' : '연락처'}로 보내드릴게요.
        </Body>

        <IconInput
          label={`가입하신 ${type === 'email' ? '이메일 주소' : '연락처'}`}
          value={accountId}
          onChange={setAccountId}
          placeholder={type === 'email' ? '이메일 주소를 입력해주세요' : '010-0000-0000'}
          icons={{ start: { src: `images/common/${type === 'email' ? 'mail' : 'phone_android'}.svg` } }}
          type={type === 'email' ? 'email' : 'tel'}
          errorMessage={errored ? (type === 'email' ? '가입되지 않은 이메일이에요' : '가입되지 않은 연락처입니다') : undefined}
          autocomplete={true}
          required={true}
          containerStyle={{ width: '100%', marginTop: '2rem' }}
        />

        <FilledButton size={'s'} color={'primary'} style={{ marginTop: '3.8rem', width: '100%' }}>
          링크 보내기
        </FilledButton>
        <TextButton
          size={'xs'}
          color={'mono'}
          style={{ margin: '0.4rem auto 0' }}
          onClick={() => setType(prev => (prev === 'email' ? 'phone' : 'email'))}
          type={'button'}
        >
          {type === 'email' ? '연락처' : '이메일'}로 가입했어요
        </TextButton>
      </Form>
    </dialog>
  );
};

const Form = styled.form`
  width: 34rem;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
  background: #fdfdfd;
  border: 1px solid #e9ebed;
  border-radius: 16px;

  &:before {
    content: '';
    width: 3.2rem;
    height: 3.2rem;
    background-image: url('/images/common/lock_black.svg');
    background-size: cover;
    margin-bottom: 0.2rem;
  }
`;
