import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { postSignInBySocial } from '../../../common/api/apiClient';
import { AppContext } from '../../../App';
import { getFallbackRedirect } from '../../../common/utils/utils';
import { doSyncRedirect } from '../../../common/utils/utils';
import { SocialLogo } from '../Login';
import { useTrack } from '@hackler/react-sdk';
import { setLastLoginType } from '../../../common/utils/storage';

{
  /* eslint-disable react/prop-types */
}
export const Naver = ({ onSocial, onToken }) => {
  const { redirect, setInvite } = useContext(AppContext);
  const [ready, setReady] = useState(false);
  const naverLogin = useRef();
  const location = useLocation();
  const loaded = useRef(false);
  const track = useTrack();

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      loadScript();
    }
  }, []);

  useEffect(() => {
    if (ready) checkLoginStatus();
  }, [ready]);

  useEffect(() => {
    window.addEventListener('message', e => {
      if (e.data.callback === 'naver' && e.data.accessToken) {
        if (redirect.includes('sync')) {
          return doSyncRedirect(redirect, e.data.accessToken);
        }

        void handleNaverSignIn(e.data.accessToken);
      }
    });
  }, []);

  const handleNaverSignIn = async access_token => {
    const res = await postSignInBySocial(redirect ?? getFallbackRedirect(), 'naver', access_token);
    if (res) {
      track({ key: 'click_login_naver' });
      onSocial({ provider: 'naver', access_token, ...res });
    } else {
      setLastLoginType('naver');
      track({ key: 'click_signup_naver' });
      onToken();
    }
  };

  const checkLoginStatus = () => {
    if (naverLogin.current) {
      naverLogin.current.getLoginStatus(status => {
        if (status) {
          // console.log(status)
        }
      });
    }
  };

  const loadScript = () => {
    const w = window;
    if (document.getElementById('naver-login-script')) {
      return console.error('Naver script loaded');
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2.js';
    script.charset = 'UTF-8';
    script.id = 'naver-login-script';
    document.head.append(script);

    script.onload = () => {
      naverLogin.current = new w.naver.LoginWithNaverId({
        clientId: 'Bf0Y2ciBsz1cz7kLu4jY',
        callbackUrl: `${window.location.origin}/social-login/naver/callback`,
        // callbackUrl: 'http://127.0.0.1:3000/social-login/naver/callback',
        callbackHandle: true,
        isPopup: true,
        loginButton: {
          color: 'green',
          type: 1,
          height: 62,
          tabIndex: -1,
        },
      });

      naverLogin.current.successCallback = data => console.log(data);

      naverLogin.current.init();
      setReady(true);
    };
  };

  const onClick = () => {
    const n = document.getElementById('naverIdLogin_loginButton');
    if (n) {
      n.click();
    }
  };

  return <SocialLogo label={'네이버'} src={'/images/login/naver.svg'} id={'naverIdLogin'} onClick={onClick} />;

  // return <div id={'naverIdLogin'} />;
};
