import React, { useContext, useRef, useState } from 'react';
import { useOpenCloseDialog } from '../utils/hooks';
import { patchUpdateUser, patchUpdateUserPhone, postSendPhoneVerification } from '../api/apiClient';
import { FlexCol } from '../../shared/FlexCol';
import { Body, Headline } from '../../shared/styled-components/styled_text';
import { IconInput } from '../../shared/components/IconInput';
import { RegEx } from '../utils/RegEx';
import { FilledButton, TextButton } from '../../shared/styled-components/styled_buttons';
import { FlexRow } from '../../shared/FlexRow';
import styled from 'styled-components/macro';
import { getSavedAuth } from '../utils/storage';
import { AppContext } from '../../App';

export const DialogEditUserInfo: React.FC<{ type: 'name' | 'email' | 'phone' | null }> = ({ type }) => {
  const [value, setValue] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [error, setError] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);

  const { editUserInfo } = useContext(AppContext);

  const self = useRef<HTMLDialogElement>(null);
  useOpenCloseDialog(self, !!type);

  const container = useRef<HTMLDivElement>(null);

  const handleSendCode = () => {
    if (type === 'phone') {
      postSendPhoneVerification(getSavedAuth()?.access_token ?? '', value)
        .then(() => setCodeSent(true))
        .catch(() => setError(true));
    }
  };

  const handleUpdate = async () => {
    switch (type) {
      case 'name': {
        patchUpdateUser(getSavedAuth()?.access_token ?? '', value).then(handleClose);
        break;
      }
      case 'phone': {
        patchUpdateUserPhone(getSavedAuth()?.access_token ?? '', value, code)
          .then(handleClose)
          .catch(() => setCodeError(true));
      }
    }
  };

  const handleClose = () => {
    setValue('');
    setCodeSent(false);
    setError(false);
    setCode('');
    setCodeError(false);
    editUserInfo.close();
  };

  return (
    <dialog ref={self}>
      {type && (
        <Container ref={container}>
          <Close onClick={handleClose} />
          <FlexCol align={'flex-start'} width={'auto'} gap={'0.2rem'}>
            <Headline size={'s'}>변경할 {mapTypeToKorean(type, true)} 입력해주세요</Headline>
            <Body size={'s'} style={{ color: 'var(--mono-60)' }}>
              {getSubtitle(type)}
            </Body>
          </FlexCol>
          <FlexCol align={'flex-start'} width={'auto'} gap={'1.2rem'}>
            <IconInput
              label={mapTypeToKorean(type)}
              value={value}
              onChange={setValue}
              placeholder={getPlaceholder(type)}
              errorMessage={error ? '이미 사용중인 휴대폰 번호예요.' : undefined}
              pattern={type === 'phone' ? RegEx.PhoneNumber : undefined}
              patternMessage={'올바른 휴대폰 번호 형식인지 확인해주세요'}
            />
            {type === 'name' && (
              <FilledButton size={'m'} color={'primary'} style={{ width: '100%' }} disabled={!value.trim().length} onClick={handleUpdate}>
                변경하기
              </FilledButton>
            )}
            {type !== 'name' && !codeSent && (
              <FilledButton
                size={'m'}
                color={'primary'}
                style={{ width: '100%' }}
                onClick={handleSendCode}
                disabled={type === 'phone' ? !RegEx.PhoneNumber.test(value) : RegEx.Email.test(value)}
              >
                인증번호 보내기
              </FilledButton>
            )}
          </FlexCol>

          {codeSent && (
            <FlexCol align={'flex-start'} width={'auto'} gap={'1.2rem'}>
              <IconInput
                label={'인증번호'}
                value={code}
                onChange={setCode}
                placeholder={'인증번호를 입력해주세요'}
                errorMessage={codeError ? '인증번호가 일치하지 않아요.' : undefined}
              />
              <FilledButton size={'m'} color={'primary'} style={{ width: '100%' }} onClick={handleUpdate} disabled={!code.trim().length}>
                인증 완료
              </FilledButton>
            </FlexCol>
          )}

          {codeSent && (
            <FlexRow>
              <Body size={'s'} style={{ color: 'var(--mono-60)' }}>
                인증번호를 받지 못하셨나요?
              </Body>
              <TextButton size={'s'} color={'mono'} onClick={handleSendCode}>
                인증번호 다시 보내기
              </TextButton>
            </FlexRow>
          )}
        </Container>
      )}
    </dialog>
  );
};

const Container = styled.div`
  width: 39.7rem;
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
  padding: var(--padding-section-tbrl);
  position: relative;
`;

const Close = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background-image: url('/images/common/close.svg');
  background-size: cover;
  position: absolute;
  top: 1.3rem;
  right: 1.6rem;
  cursor: pointer;
`;

const mapTypeToKorean = (type: 'name' | 'email' | 'phone', particle?: boolean) => {
  switch (type) {
    case 'name':
      return `이름${particle ? '을' : ''}`;
    case 'email':
      return `이메일${particle ? '를' : ''}`;
    case 'phone':
      return `연락처${particle ? '를' : ''}`;
  }
};

const getSubtitle = (type: 'name' | 'email' | 'phone'): JSX.Element | null => {
  switch (type) {
    case 'email':
      return (
        <>
          변경한 이메일로 인증번호를 보내드립니다. <br /> 발급된 인증번호를 입력하여 인증을 완료해주세요.
        </>
      );
    case 'phone':
      return (
        <>
          변경한 연락처로 인증번호를 보내드립니다. <br />
          발급된 인증번호를 입력하여 인증을 완료해주세요.
        </>
      );
    default:
      return null;
  }
};

const getPlaceholder = (type: 'name' | 'email' | 'phone') => {
  switch (type) {
    case 'email':
      return '변경할 이메일을 입력해주세요';
    case 'phone':
      return '변경할 연락처를 입력해주세요';
    case 'name':
      return '변경할 이름을 입력해주세요';
  }
};
