import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { useUser } from '../../../utils/hooks';
import { IconInput } from '../../../../shared/components/IconInput';
import { FilledButton, TextButton } from '../../../../shared/styled-components/styled_buttons';
import { AppContext } from '../../../../App';

export const UserInfoBasic: React.FC = () => {
  const { user, refetch } = useUser();
  const { userInfo, changePassword, editUserInfo } = useContext(AppContext);

  const handleChangePassword = () => {
    userInfo.close();
    changePassword.open();
  };

  const handleEditUseInfo = (type: 'name' | 'email' | 'phone') => {
    editUserInfo.open(type, refetch);
  };

  return (
    <Container>
      <ScrollWrapper>
        <Wrapper>
          <IconInput
            label={'이메일'}
            value={user?.email ?? ''}
            onChange={() => undefined}
            icons={{
              end2: user?.email
                ? {
                    src: '/images/common/verified.svg',
                    width: '6.9rem',
                  }
                : undefined,
            }}
            containerStyle={{ gridColumn: '1 / 3' }}
            disabled
          />

          <IconInput label={'이름'} value={user?.name ?? ''} onChange={() => undefined} disabled />
          <FilledButton size={'s'} color={'mono'} onClick={() => handleEditUseInfo('name')}>
            변경하기
          </FilledButton>

          <IconInput
            label={'휴대폰번호'}
            value={user?.phone ?? ''}
            onChange={() => undefined}
            icons={{
              end2: user?.phone
                ? {
                    src: '/images/common/verified.svg',
                    width: '6.9rem',
                  }
                : undefined,
            }}
            disabled
          />
          <FilledButton size={'s'} color={'mono'} onClick={() => handleEditUseInfo('phone')}>
            {user?.phone ? '변경하기' : '등록하기'}
          </FilledButton>
        </Wrapper>
        <TextButton size={'s'} color={'mono'} onClick={handleChangePassword} style={{ marginLeft: 'auto', marginTop: '4.8rem' }}>
          비밀번호 변경
        </TextButton>
      </ScrollWrapper>
    </Container>
  );
};

const Container = styled.div`
  flex-grow: 1;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  align-items: end;
  grid-template-columns: 1fr 8rem;
  column-gap: 0.6rem;
  row-gap: 2.2rem;
  padding-top: 2.4rem;
`;
