import styled from 'styled-components/macro';

export const Container = styled.div<{
  form?: DOMRect;
  offset1?: { top?: string; left?: string };
  offset2?: { top?: string; left?: string };
}>`
  width: 100vw;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
  //padding: 4.6rem 0;
  gap: 3.2rem;
  box-sizing: border-box;

  &:before {
    content: '';
    width: 13.7rem;
    height: 13.7rem;
    background-image: url('/images/login/background.png');
    background-size: cover;
    z-index: -1;
    position: absolute;
    top: calc(${props => `${props.form?.top ?? 0}px + ${props.offset1?.top ?? '1rem'}`});
    left: calc(${props => `${props.form?.left ?? 0}px + ${props.offset1?.left ?? '17rem'}`});
  }

  &:after {
    content: '';
    width: 13.7rem;
    height: 13.7rem;
    background-image: url('/images/login/background.png');
    background-size: cover;
    z-index: -1;
    transform: rotate(45deg) scale(0.7);
    position: absolute;
    top: calc(${props => `${props.form?.top ?? 0}px + ${props.offset2?.top ?? '39rem'}`});
    left: calc(${props => `${props.form?.left ?? 0}px - ${props.offset2?.left ?? '7rem'}`});
  }
`;

export const Form = styled.form`
  min-width: 32rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 3rem 1.9rem 3.6rem;
  gap: 2.2rem;
  border: 1px solid #cacdd2;
  border-radius: 16px;
  background-color: white;
  margin: 1.8rem 0;
  position: relative;
  z-index: 10;
  opacity: 0.95;
  box-sizing: border-box;

  @media only screen and (min-width: 500px) {
    min-width: 40rem;
  }

  #naverIdLogin_loginButton {
    img {
      border-radius: 50%;
      width: 5rem;
      height: 5rem;
    }
  }

  .socialBtn {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-size: 31px 31px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    &.kakao {
      background-color: #fee500;
      background-image: url('/images/login/KakaoTalk_logo.svg');
      background-size: 4rem;
      background-position: center;
    }

    &.google {
      background-image: url('/images/login/Google_Logo.svg');
      background-size: 2.6rem;
      background-position: center;
      border: 1px solid #e9ebed;
      width: 5rem;
      height: 5rem;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-top: 1px solid var(--light);
`;

export const ButtonSentence = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  font-size: 1.4rem;
  cursor: pointer;
  margin-top: -1.8rem;

  &:before {
    font-weight: 400;
    color: var(--mono-40);
  }

  &:after {
    color: var(--mono-40);
    font-weight: 500;
  }
`;
