import { AuthResponse } from '../../shared/types/responseTypes';

const CANDIDATE_AUTH = 'candidate-auth';
export const setSavedAuth = (val: AuthResponse | null) => {
  val ? localStorage.setItem(CANDIDATE_AUTH, JSON.stringify(val)) : localStorage.removeItem(CANDIDATE_AUTH);
};
export const getSavedAuth = (): AuthResponse | null => {
  const res = localStorage.getItem(CANDIDATE_AUTH);
  if (res) return JSON.parse(res);
  return null;
};

export type LastSaveType = 'google' | 'naver' | 'kakao' | 'email';
export const LAST_LOGIN_TYPE = 'key_last_login_type';
export const setLastLoginType = (type: LastSaveType) => localStorage.setItem(LAST_LOGIN_TYPE, type);
export const getLastLoginType = (): LastSaveType | null => localStorage.getItem(LAST_LOGIN_TYPE) as LastSaveType | null;
