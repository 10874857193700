import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useOnClickOutside, useOpenCloseDialog } from '../utils/hooks';
import { IconInput } from '../../shared/components/IconInput';
import { AppContext } from '../../App';
import { patchChangePassword } from '../api/apiClient';
import { getSavedAuth } from '../utils/storage';
import { RegEx } from '../utils/RegEx';

interface Props {
  open: boolean;
}

export const DialogChangePassword: React.FC<Props> = ({ open }) => {
  const [password1, setPassword1] = useState<string>();
  const [password2, setPassword2] = useState<string>();
  const { changePassword } = useContext(AppContext);
  const self = useRef<HTMLDialogElement>(null);
  useOpenCloseDialog(self, open);
  const container = useRef<HTMLDivElement>(null);
  useOnClickOutside(container, changePassword.close);

  const handleSubmit = async () => {
    try {
      const auth = getSavedAuth();
      await patchChangePassword(auth!.access_token, password1!);
      alert('비밀번호 변경이 완료되었어요.');
      changePassword.close();
    } catch (e) {
      alert(e);
    }
  };

  return (
    <dialog ref={self}>
      <Container ref={container}>
        <Lock src={'/images/common/lock_black.svg'} />
        <Title>새로운 비밀번호로 변경하기</Title>
        <SubTitle>영문+숫자 8자 이상 입력해주세요</SubTitle>

        <IconInput
          label={'새 비밀번호'}
          type={'password'}
          style={{ marginTop: '1.8rem' }}
          value={password1 ?? ''}
          onChange={setPassword1}
          icons={{ start: { src: '/images/common/lock.svg' } }}
          placeholder={'새 비밀번호를 입력해주세요'}
          patternMessage={'영문+숫자 8자 이상 입력해주세요.'}
          pattern={RegEx.Password}
        />

        <IconInput
          label={'새 비밀번호 확인'}
          type={'password'}
          style={{ marginTop: '1.8rem' }}
          value={password2 ?? ''}
          onChange={setPassword2}
          icons={{ start: { src: '/images/common/lock.svg' } }}
          placeholder={'새 비밀번호를 입력해주세요'}
          patternMessage={'작성한 비밀번호와 일치하지 않아요.'}
          pattern={value => value === password1}
        />

        <Submit disabled={!password1 || !password2 || password1 !== password2 || !RegEx.Password.test(password1)} onClick={handleSubmit}>
          비밀번호 변경하기
        </Submit>
      </Container>
    </dialog>
  );
};

const Container = styled.div`
  width: 42.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 3rem 4rem;
`;

const Lock = styled.img`
  width: 3.2rem;
  height: 3.2rem;
  object-fit: cover;
`;

const Title = styled.div`
  font-size: 2.4rem;
  font-weight: 700;
  color: var(--mono-10);
`;

const SubTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--mono-10);
`;

const Submit = styled.button`
  border: none;
  outline: none;
  margin: 1.8rem 0;
  height: 3.6rem;
  background-color: var(--primary);
  color: white;
  font-weight: 500;
  font-size: 1.4rem;
  border-radius: 4px;

  &:disabled {
    color: var(--mono-80);
    background: var(--light-1);
  }
`;
