import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { getSavedAuth } from './storage';
import { getUser } from '../api/apiClient';
import { User } from '../../shared/types/userTypes';

export const useBoundingClient = <T extends HTMLElement>(
  target: RefObject<T>,
  scrollTarget?: string | RefObject<T>,
  skipCalculation?: boolean,
  deps: unknown[] = [],
) => {
  const [client, setClient] = useState<DOMRect>();

  const { cancel, throttledFn } = useThrottledFunction({ callbackFn: throttleCallback, throttleMs: 10 });

  function throttleCallback() {
    if (shouldSetClient()) {
      getClient();
    }
  }

  function shouldSetClient() {
    if (!client) return true;
    if (!skipCalculation) return true;
    return false;
  }

  function getClient() {
    if (target.current) {
      setClient(target.current.getBoundingClientRect());
    }
  }

  // Ensure client is always calculated on mount
  useEffect(getClient, [target, ...deps]);

  // Setup scroll handler
  useEffect(() => {
    if (typeof scrollTarget === 'string') {
      const el = document.querySelector(scrollTarget);
      if (el) {
        el.addEventListener('scroll', throttledFn);
      }
    } else if (scrollTarget) {
      scrollTarget.current?.addEventListener('scroll', throttledFn);
    } else {
      window.addEventListener('scroll', throttledFn);
    }
  }, [scrollTarget]);

  useEffect(() => {
    window.addEventListener('resize', throttledFn);

    return () => {
      window.removeEventListener('resize', throttledFn);
      window.removeEventListener('scroll', throttledFn);
    };
  }, []);

  return { client, recalculate: throttledFn };
};

export type useThrottledFunctionProps = {
  callbackFn: <T>(args?: T) => any;
  throttleMs?: number;
};

const DEFAULT_THROTTLE_MS = 800;
export const useThrottledFunction = ({ callbackFn, throttleMs = DEFAULT_THROTTLE_MS }: useThrottledFunctionProps) => {
  const lastTriggered = useRef<number>(Date.now());
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const cancel = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  const throttledFn = useCallback(
    <T>(args?: T) => {
      let remainingTime = getRemainingTime(lastTriggered.current, throttleMs);

      if (remainingTime <= 0) {
        lastTriggered.current = Date.now();
        callbackFn(args);
        cancel();
      } else if (!timeoutRef.current) {
        timeoutRef.current = setTimeout(() => {
          remainingTime = getRemainingTime(lastTriggered.current, throttleMs);

          if (remainingTime === 0) {
            lastTriggered.current = Date.now();
            callbackFn(args);
            cancel();
          }
        }, remainingTime);
      }
    },
    [callbackFn, cancel],
  );

  useEffect(() => cancel, [cancel]);

  return { cancel, throttledFn };
};

const getRemainingTime = (lastTriggeredTime: number, throttleMs: number) => {
  const elapsedTime = Date.now() - lastTriggeredTime;
  const remainingTime = throttleMs - elapsedTime;

  return remainingTime < 0 ? 0 : remainingTime;
};

export const useOnClickOutside = <T extends HTMLElement, U extends HTMLElement>(
  ref: RefObject<T>,
  handler: () => void,
  except?: RefObject<U>,
) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || !event.target || ref.current.contains(event.target) || (except && except.current?.contains(event.target))) {
        return;
      }
      handler();
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const useUser = () => {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    refetch();
  }, []);

  const refetch = () => {
    const auth = getSavedAuth();
    if (auth) {
      getUser(auth.access_token)
        .then(setUser)
        .catch(() => setUser(undefined));
    }
  };

  return { user, refetch };
};

export const useOpenCloseDialog = (ref: RefObject<HTMLDialogElement>, isOpen: boolean, onOpen?: () => void, onClose?: () => void) => {
  useEffect(() => {
    if (isOpen && ref.current && !ref.current?.open) {
      ref.current.showModal();
      if (onOpen) onOpen();
    } else if (!isOpen && ref.current?.open) {
      if (onClose) onClose();
      ref.current.close();
    }
  }, [isOpen]);
};
