import React, { useEffect } from 'react';
import { setSavedAuth } from '../common/utils/storage';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../AppRoutes';

export const Logout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setSavedAuth(null);
    navigate(appRoutes.login.path());
  }, []);

  return null;
};
