import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Login } from './pages/login/Login';

import { ResetPassword } from './pages/login/ResetPassword';
import { Logout } from './pages/Logout';
import { UpdateAuth } from './pages/UpdateAuth';
import { SignUp } from './pages/sign-up/SignUp';
import { AppContext } from './App';
import { LegacyVerification } from './pages/sign-up/LegacyVerification';
import { InviteLanding } from './pages/invite/InviteLanding';
import { CreateWorkspace } from './pages/create-workspace/CreateWorkspace';

export const appRoutes = {
  login: {
    route: '/login',
    path: () => '/login',
    children: {
      naverCallback: {
        route: '/social-login/naver/callback',
        path: () => '/social-login/naver/callback',
      },
      appleCallback: {
        route: `/social-login/apple/callback`,
        path: () => `/social-login/apple/callback`,
      },
      googleCallback: {
        route: `/social-login/google/callback`,
        path: () => `/social-login/google/callback`,
      },
    },
  },
  inviteAccept: {
    route: '/invite-accept',
    path: () => '/invite-accept',
  },
  invite: {
    route: '/invite/:token',
  },
  signUp: {
    route: '/sign-up',
    path: () => `/sign-up`,
  },
  createWorkspace: {
    route: '/create-workspace',
    path: () => '/create-workspace',
  },
  legacyVerification: {
    route: '/sign-up/verified',
  },
  resetPassword: {
    route: '/reset-password',
    path: () => `/reset-password`,
  },
  logout: {
    route: '/logout',
  },
  updateAuth: {
    route: '/updateAuth',
  },
};

const AppRoutes: React.FC = () => {
  const { redirect } = useContext(AppContext);

  return (
    <>
      {redirect && (
        <Routes>
          <Route path={appRoutes.logout.route} element={<Logout />} />
          <Route path={appRoutes.login.route} element={<Login />} />
          <Route path={appRoutes.login.children.naverCallback.route} element={<Login />} />
          <Route path={appRoutes.login.children.appleCallback.route} element={<Login />} />
          <Route path={appRoutes.signUp.route} element={<SignUp />} />
          <Route path={appRoutes.invite.route} element={<InviteLanding />} />
          <Route path={appRoutes.inviteAccept.route} element={<InviteLanding asAccept />} />
          <Route path={appRoutes.createWorkspace.route} element={<CreateWorkspace />} />
          <Route path={appRoutes.legacyVerification.route} element={<LegacyVerification />} />
          <Route path={appRoutes.resetPassword.route} element={<ResetPassword />} />
          <Route path={appRoutes.updateAuth.route} element={<UpdateAuth />} />
          <Route path={'/'} element={<Login />} />
          <Route path={'*'} element={<Login />} />
        </Routes>
      )}
    </>
  );
};

export default AppRoutes;
