import React, { useContext, useEffect } from 'react';
import { AppContext } from '../App';
import { setSavedAuth } from '../common/utils/storage';

export const UpdateAuth: React.FC = () => {
  const { redirect } = useContext(AppContext);

  useEffect(() => {
    if (redirect) {
      const auth = new URLSearchParams(window.location.search).get('auth');
      if (auth) {
        setSavedAuth(JSON.parse(auth));
      }
      window.location.href = redirect;
    }
  }, [redirect]);

  return null;
};
