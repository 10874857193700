import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { useOpenCloseDialog, useUser } from '../../utils/hooks';
import { AppContext } from '../../../App';
import { Headline } from '../../../shared/styled-components/styled_text';
import { UserInfoBasic } from './components/UserInfoBasic';
import { UserInfoAccount } from './components/UserInfoAccount';
import { deleteUserProfileImage, postUpdateUserProfileImage } from '../../api/apiClient';
import { getSavedAuth } from '../../utils/storage';
import { postFetchUploadURLs, postUploadSuccess, uploadFile } from '../../api/fileClient';

interface Props {
  open: boolean;
}

export const DialogUserInfo: React.FC<Props> = ({ open }) => {
  const [section, setSection] = useState<'basic' | 'account'>('basic');
  const { user, refetch } = useUser();
  const self = useRef<HTMLDialogElement>(null);
  useOpenCloseDialog(
    self,
    open,
    () => refetch(),
    () => {
      setSection('basic');
      setFiles(null);
      setUploading(false);
    },
  );
  const fileInput = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<FileList | null>(null);
  const [uploading, setUploading] = useState(false);

  const { userInfo } = useContext(AppContext);

  useEffect(() => {
    if (files) {
      handleFileUpload();
    }
  }, [files]);

  const handleAvatarClick = () => {
    if (!user?.profile?.file && fileInput.current) {
      fileInput.current.click();
    } else if (user?.profile?.file) {
      const auth = getSavedAuth();
      if (auth) {
        deleteUserProfileImage(auth.access_token).then(refetch);
      }
    }
  };

  const handleFileUpload = async () => {
    if (files?.item(0)) {
      const auth = getSavedAuth();
      try {
        setUploading(true);
        const res = await postFetchUploadURLs(auth!.access_token, [
          {
            type: 'profile',
            name_org: files?.item(0)!.name,
          },
        ]);
        const uuid = res[0];
        await uploadFile(files.item(0)!, uuid.pre_signed_url!);
        delete uuid.pre_signed_url;
        await postUploadSuccess(auth!.access_token, [{ ...uuid }]);
        await postUpdateUserProfileImage(auth!.access_token, uuid.uuid);
        refetch();
      } catch (e) {
        console.error(e);
      } finally {
        setUploading(false);
        setFiles(null);
      }
    }
  };

  return (
    <Dialog ref={self} onClose={userInfo.close} onCancel={userInfo.close}>
      {open && (
        <Container>
          <Left>
            {!uploading && <Avatar src={user?.profile?.file.path_full} onClick={handleAvatarClick} />}
            {uploading && <UploadSpinner className={'loading-indicator'} />}
            <MenuItem
              onClick={() => setSection('basic')}
              active={section === 'basic'}
              icon={'/images/common/account.svg'}
              text={'기본 정보'}
            />
            <MenuItem
              onClick={() => setSection('account')}
              active={section === 'account'}
              icon={'/images/common/manage_accounts.svg'}
              text={'계정 정보 수정'}
            />
            {/*<MenuItem active={false} icon={'/images/common/conversion_path.svg'} text={'서비스 연동'} />*/}
            <FileInput
              ref={fileInput}
              type={'file'}
              accept={'image/png, image/jpg, image/jpeg, image/webp'}
              onChange={e => setFiles(e.target.files)}
            />
          </Left>
          <Right>
            <Header>
              <Headline size={'s'}>{section === 'basic' ? '기본 정보' : '계정 정보 수정'}</Headline>
              <img src='/images/common/close.svg' alt='close' onClick={userInfo.close} style={{ cursor: 'pointer' }} />
            </Header>
            {section === 'basic' && <UserInfoBasic />}
            {section === 'account' && <UserInfoAccount />}
          </Right>
        </Container>
      )}
    </Dialog>
  );
};

const Container = styled.div`
  width: 62.5rem;
  min-height: 58.7rem;
  display: flex;
  background-color: white;
  overflow: hidden;
`;

const Dialog = styled.dialog``;

const Left = styled.div`
  width: 22rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--light-2);
`;

const Right = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  flex-grow: 1;
  padding: 2.2rem;
  background-color: #fdfdfd;
  overflow: hidden;
`;

const Avatar = styled.div<{ src?: string }>`
  width: 10rem;
  height: 10rem;
  margin: 2.2rem auto 2.6rem;
  border-radius: 8px;
  background-image: url(${props => props.src ?? '/images/common/user_circle.svg'});
  background-size: ${props => (props.src ? '100%' : '5rem')};
  background-position: center;
  background-repeat: no-repeat;
  object-fit: contain;
  background-color: ${props => (props.src ? 'white' : 'var(--primary-60)')};
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &:after {
    content: '${props => (props.src ? '이미지 초기화' : '이미지 변경')}';
    width: 100%;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.4rem;
    position: absolute;
    bottom: 0;
    background-color: rgba(1, 1, 1, 0.7);
  }
`;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const UploadSpinner = styled.div`
  width: 10rem;
  height: 10rem;
  margin: 2.2rem auto 2.6rem;
  border-radius: 50%;
  border: 10px solid #f3f3f3;
  border-top: 10px solid var(--primary);
  border-left: 10px solid var(--primary);
  border-bottom: 10px solid var(--primary);
  animation: ${spin} 1.4s linear infinite;
`;

const FileInput = styled.input`
  width: 0;
  height: 0;
  visibility: hidden;
`;

const MenuItem = styled('div')<{ active: boolean; icon: string; text: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1.6rem 2.4rem;
  gap: 0.8rem;
  height: 5.2rem;
  width: 100%;
  cursor: pointer;
  background: ${props => (props.active ? 'var(--primary-op-08)' : 'white')};
  border-radius: 6px;
  font-weight: 500;
  box-sizing: border-box;

  &:hover,
  &:active {
    background: var(--mono-40-op-08);
  }

  &:focus {
    outline: none;
  }

  &:before {
    content: '';
    width: 2rem;
    height: 2rem;
    background-image: url(${props => props.icon});
    background-size: cover;
    filter: ${props => (props.active ? 'var(--primary-filter)' : 'none')};
  }

  &:after {
    content: '${props => props.text}';
    font-size: 1.6rem;
    color: ${props => (props.active ? 'var(--primary)' : 'var(--mono-40)')};
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > img {
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
  }
`;
