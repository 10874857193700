import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Body } from '../../../shared/styled-components/styled_text';

export interface AgreementsCheck {
  terms: boolean;
  privacy: boolean;
  service: boolean;
}

interface Props {
  onToggle: (args: AgreementsCheck) => void;
  style?: React.CSSProperties;
}

export const SignUpAgreements: React.FC<Props> = ({ onToggle, style }) => {
  const [all, setAll] = useState(false);
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [service, setService] = useState(false);

  useEffect(() => {
    if (terms && privacy && service) {
      setAll(true);
    } else {
      setAll(false);
    }
    onToggle({ terms, privacy, service });
  }, [terms, privacy, service]);

  const handleAllClick = () => {
    setTerms(!all);
    setPrivacy(!all);
    setService(!all);
    setAll(prev => !prev);
  };

  return (
    <Container style={style}>
      <CheckRow>
        <input type='checkbox' checked={all} onChange={handleAllClick} />
        <Body size={'s'} style={{ cursor: 'pointer' }} onClick={handleAllClick}>
          모두 동의합니다.
        </Body>
      </CheckRow>
      <Divider />

      <CheckRow>
        <input type='checkbox' checked={terms} onChange={() => setTerms(prev => !prev)} required={true} />
        <Body size={'s'} style={{ cursor: 'pointer' }} onClick={() => setTerms(prev => !prev)}>
          <span style={{ color: 'red' }}>필수</span> 이용약관 동의
        </Body>
        <a href='https://www.candidate.im/teams/use' target={'_blank'} rel='noreferrer'>
          자세히보기
        </a>
      </CheckRow>
      <CheckRow>
        <input type='checkbox' checked={privacy} onChange={() => setPrivacy(prev => !prev)} required={true} />
        <Body size={'s'} style={{ cursor: 'pointer' }} onClick={() => setPrivacy(prev => !prev)}>
          <span style={{ color: 'red' }}>필수</span> 개인정보 수집 및 이용 동의
        </Body>
        <a href='https://www.candidate.im/teams/privacy' target={'_blank'} rel='noreferrer'>
          자세히보기
        </a>
      </CheckRow>
      <CheckRow>
        <input type='checkbox' checked={service} onChange={() => setService(prev => !prev)} required={false} />
        <Body size={'s'} style={{ cursor: 'pointer' }} onClick={() => setService(prev => !prev)}>
          <span style={{ color: 'red' }}>선택</span> 서비스 정보 수신 동의
        </Body>
        <a href='https://www.candidate.im/teams/info-agree' target={'_blank'} rel='noreferrer'>
          자세히보기
        </a>
      </CheckRow>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CheckRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;

  > input {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  > div {
    flex-grow: 1;
  }

  > a {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 160%;
    color: var(--mono-60);
    text-decoration: none;
  }
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid #e9ebed;
`;
